import React from 'react'
import { Link } from "gatsby"
import { Container, Row, Col, Button } from 'reactstrap';
import Header from '../../components/Header';
import Footer from '../../components/Footer'
import Helmet from 'react-helmet'
import styled from 'styled-components';
import Layout from '../../components/Layout'

const BlogImage = styled.img`
  border: 1px solid rgba(0,0,0,.15);
`
const BlogRow = styled(Row)`
  margin-bottom: 45px;
  @media(max-width:767px) {
    margin-bottom: 0;
  }
`
const BlogExcerpt = styled(Link)`
  font-size: 20px;
  line-height: 1.4;
  color: rgba(0,0,0,.54); fill: rgba(0,0,0,.54);
  margin-top: 7px;
  font-weight: 400;
  display: inline-block;
  &:hover {
    text-decoration: none;
    color:rgba(0,0,0,.54);
  }
  @media(max-width:991px) {
    font-size: 14px;
  }
`
const BlogFirstHeader = styled(Link)`
  font-size: 2.5rem;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  color:inherit; font-family: inherit;
  display: block;
  &:hover {
    color:inherit;
    text-decoration: none;
  }
  @media(max-width: 991px) {
    font-size: 24px;
  }
  @media(max-width:767px) {
    padding-top: 15px;
  }
`
const BlogOtherHeader = styled(Link)`
  padding-top: 15px;
  font-size: 1.75rem;
  margin-bottom: .5rem;
  line-height: 1.2;
  font-weight: 500;
  display:block;
  font-family: inherit; color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
  @media(max-width:767px) {
    padding-top: 15px; 
  }
`
const BlogDate = styled.div`
  color: rgba(0,0,0,.54); fill: rgba(0,0,0,.54);
  font-size: 14px;
  margin-top: 7px;
  @media(max-width:767px) {
    margin-bottom: 25px;
  }
`

const displayBlogPosts = (posts) => {
  if(posts.length === 0) {
    return;
  }

  const mappedPosts = posts.map((x,i) => {
    return (i-1) % 3 === 0 ? posts.slice(i, i+3) : null
  }).filter((x,i) => x != null && i != 0)

  return (
    <div>
      <BlogRow>
        <Col xs="12" md="8">
          <Link to={'/blog' + posts[0].node.fields.slug}><BlogImage src={posts[0].node.frontmatter.image.publicURL} /></Link>
        </Col>
        <Col xs="12" md="4">
          <BlogFirstHeader to={'/blog' + posts[0].node.fields.slug}>{posts[0].node.frontmatter.title}</BlogFirstHeader>
          <BlogExcerpt to={'/blog' + posts[0].node.fields.slug}>{posts[0].node.excerpt}</BlogExcerpt>
          <BlogDate>{posts[0].node.frontmatter.date}</BlogDate>
        </Col>
      </BlogRow>

      {mappedPosts.map((result, i) => (
        <BlogRow key={i}>
          {result.map(post => (
              <Col xs="12" md="4" key={post.node.id}>
                <div><Link to={'/blog' + post.node.fields.slug}><BlogImage src={post.node.frontmatter.image.publicURL} /></Link></div>
                <BlogOtherHeader to={'/blog' + post.node.fields.slug}>{post.node.frontmatter.title}</BlogOtherHeader>
                <BlogExcerpt to={'/blog' + post.node.fields.slug}>{post.node.excerpt}</BlogExcerpt>
                <BlogDate>{post.node.frontmatter.date}</BlogDate>
              </Col>
          ))}
        </BlogRow>
        )
      )}
    </div>
  )
}
export default ({data}) => (
  <Layout>
    <Helmet
        title="Our Blog | Bishop Software"
        meta={[
          { name: 'description', content: 'Bishop Software offers custom software development, web design, mobile development, software consulting, and user experience services to businesses like yours. Let\'s talk.' },
          { name: 'keywords', content: 'blog,news,software,development,web,design,mobile,consultancy,consulting,website,application,react,ios,android' }
        ]}
      />

    <section style={{paddingTop: '75px', display: 'block', backgroundImage: 'linear-gradient(132deg, #14134e 0, #512d5a 56%, #843b61 81%, #c16858 100%)', color: '#fafafa'}}>
      <Header dropdownGradient='linear-gradient(180deg, #d15e95 0, #c45d50 100%)' />

      <Container className="text-center" style={{paddingTop: '1.875rem'}}>
          <div style={{margin: '55px 0 0 0'}}>
            <h1 style={{color: '#ffc092', fontSize: '40px', fontWeight: 300}}>
              Our Blog
            </h1>
            <h3 style={{fontSize: '18px', padding: '25px 0 45px 0', fontWeight: 400, lineHeight: '24px'}}>It's a bloggy blog world out there.</h3>
          </div>
        </Container>
    </section>

    <section style={{padding: '70px 0'}}>
      <Container>
        {displayBlogPosts(data.allMarkdownRemark.edges)}
      </Container>
    </section>

    <Footer />
  </Layout>
)

export const query = graphql`
  query BlogIndexQuery {
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM Do, YYYY")
            image {
              publicURL
            }
          }
          fields {
            slug
          }
          html
          excerpt
        }
      }
    }
  }
`